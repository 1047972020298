import { authenticate } from "../store/api/ServerApi";
import { ServerStore } from "../store/domainstore/ServerStore";

const ServerConfig = async () => {
  ServerStore.account = {email: 'torooc@torooc.com', password: 'robot2021'};
  ServerStore.BASE_URL = 'https://fastapi.torooc.net/liku';
  // await authenticate().then(r => {
  ServerStore.tokenJson = {token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0b3Jvb2NAdG9yb29jLmNvbSIsImV4cCI6MjAyMTM0NDk3Mn0.qF_EJgjOiId2e24jTTq8C0wBv0P6ZjdzZ9FaYyw_U-g'};
  //   console.log('ServerStore.tokenJson', ServerStore.tokenJson);
  // });
};

export default ServerConfig;
