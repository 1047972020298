import {ServerStore} from "../../store/domainstore/ServerStore";
import {
    postTokenFetchText,
    postFormDataFetchJson, getTokenFetchText
} from "../../service/api/ServerApi";

const LikuRepository = {
    async getLikuInfo(data) {
        let url = `${ServerStore.BASE_URL}/likus?serial=${data.serial}`;
        return await getTokenFetchText(url).then(r => {
            console.log('rrr', r);
            let value = r;
            try {
                value = JSON.parse(r.replaceAll('"{','{').replaceAll('}"','}'));
            } catch (error) {}
            return value;
        });
    },

    async getLikuVersion(data) {
        const url = `https://liku.likuwithme.com/liku/new/update`;
        return await postFormDataFetchJson(url, data).then(r => {
            console.log('getLikuVersion r', r);
            return r;
        });
    }
};

export default LikuRepository;
