import { ServerStore } from "../../store/domainstore/ServerStore";

export async function authenticate() {
    let URL = `${ServerStore.BASE_URL}/authenticate`;
    let BODY = ServerStore.account;

    return await fetch(URL, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(BODY),
    })
        .then(text => text.json())
        .then(result => {
            // console.log('authenticate:', result);
            return result;
        })
        .catch(error => {
            // console.log('authenticate : ', error);
        });
}

export async function postFormDataFetchJson(URL, BODY) {
    return await fetch(URL, {
        method: 'POST',
        body: BODY,
    })
        .then(text => text.json())
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log('serverFetch : ', error);
        });
}

export async function postTokenFetchText(URL, BODY) {
    return await fetch(URL, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ServerStore.tokenJson.token}`,
        },
        Connection: 'close',
        type: 'getUserData',
        body: JSON.stringify(BODY),
    })
        .then(text => text.text())
        .then(result => {
            console.log('result', result);
            return result;
        })
        .catch(error => {
            console.log('serverFetch : ', error);
        });
}

export async function getTokenFetchText(URL) {
    return await fetch(URL, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ServerStore.tokenJson.token}`
        },
    })
        .then(text => text.text())
        .then(result => {
            console.log('result', result);
            return result;
        })
        .catch(error => {
            console.log('serverFetch : ', error);
        });
}

export async function postTokenFetchJson(URL, BODY) {
    return await fetch(URL, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ServerStore.tokenJson.token}`,
        },
        body: JSON.stringify(BODY),
    })
        .then(text => text.json())
        .then(result => {
            return result;
        })
        .catch(error => {
            // console.log('serverFetch : ', error);
        });
}
