import { getCookie, setCookie } from "./cookie";

export async function get_uuid() {
  // let URL_2 = "https://api.likuwithme.com";
  const TEST_URL = 'https://dev-api.likuwithme.com/liku';
  const BASE_URL = 'https://fastapi.torooc.net/liku';
  let TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0b3Jvb2NAdG9yb29jLmNvbSIsImV4cCI6MjAyMTM0NDk3Mn0.qF_EJgjOiId2e24jTTq8C0wBv0P6ZjdzZ9FaYyw_U-g";
  let URL = BASE_URL + "/likus";

  // console.log({serial: getCookie('likuSerial')})
  const l = await getCookie('likuSerial');
  return await fetch(`${URL}?serial=${l}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TOKEN}`
      },
      // body: JSON.stringify({serial:l }),
  })
    .then(text => text.text())
    .then(result => {
     let uuid = JSON.parse(result.replaceAll('"{', '{').replaceAll('}"', '}')).uuid
      console.log('uuid : ', uuid);
      setCookie('uuid', uuid, {path: '/', maxAge: 72000000});
      return uuid;
    })
    .catch(error => {
      // console.log('error : ', error);
    });
}

